.LinkStyle {
  color: black;
}
.shadowClass {
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  /* border-top: 1px solid #e5e5f0; */
  border-top: 1px solid #ececfa;
}
.shadowClass:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.card_body {
  height: 150px !important;
  display: flex !important;
  padding: 0px !important;
}
.Icon_col {
  font-size: 40px !important;
  color: #f36292 !important;
}
