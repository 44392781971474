body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** Datatable Edit - Temp */
.MuiDataGrid-root {
  width: 100% !important;
}
.MuiDataGrid-main {
  width: 100% !important;
}
.MuiDataGrid-row .MuiDataGrid-cell {
  overflow: initial !important;
  word-break: break-all !important;
  white-space: normal !important;
}
.MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent {
  /* overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-wrap: break-word !important; */

  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* white-space: nowrap !important; */

  /* word-wrap: break-word !important; */
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}
/* .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: inherit !important;
  word-break: break-all !important;
  white-space: normal !important;
} */
/* ----------- */

.MuiDataGrid-row .MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 18px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaders {
  line-height: 16px !important;
}
.MuiDataGrid-root .MuiDataGrid-virtualScroller {
  overflow-x: scroll !important;
}

/* .MuiDataGrid-cellCheckbox {
  position: sticky !important;
  left: 0 !important;
  background-color: #ffffff !important;
} */
/* .MuiDataGrid-cell-action-customstyles {
  position: sticky !important;
  right: 0 !important;
  background-color: #ffffff !important;
  margin-left: 15px !important;
} */

/* -------------------------------------- */

.typehead_form_control {
  position: relative !important;
}
.typehead_form_control .form-control {
  padding-right: 34px !important;
}
.typehead_form_control_toggle_button {
  content: "";
  position: absolute;
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  right: 0;
  top: 0;
}
.typehead_form_control_toggle_button span {
  font-size: 18px;
  font-weight: bold;
  color: #1b1b1b !important;
}

/* .MuiDataGrid-main
  .MuiDataGrid-columnHeaderTitleContainerContent
  .MuiDataGrid-checkboxInput.MuiCheckbox-root {
  background-color: rgba(255, 0, 0, 0.596) !important;
} */
/* .MuiDataGrid-cell-action-column-Header {
  background-color: rgba(255, 0, 0, 0.596) !important;
} */

/* -------------------------------------- */

/* Datatables bottom paginations */
.MuiTablePagination-toolbar {
  display: flex !important;
  align-items: center !important;
}
.MuiTablePagination-toolbar .MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}
.MuiTablePagination-toolbar .MuiTablePagination-select {
  margin-bottom: 0px !important;
}
.MuiTablePagination-toolbar .MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}
.MuiTablePagination-toolbar .MuiTablePagination-actions {
  margin-bottom: 0px !important;
}

/* -------------------------------------- */
