.button-labels{
    display: -webkit-box;
}
.button-labels label { 
    display: inline-block; 
    border: solid 1px #ddd; 
    background-color: #eee;
    padding: 3px;
    min-width: 90px;
    text-align: center;
    margin-left: 1rem;
}