#basket {
  font-size: 30px !important;
}

.quantity__box {
  display: flex;
}
.quantity__box p {
  margin-right: 4px;
  margin-top: 8px;
  margin-bottom: 0;
}
.quantity__box .quantity__button {
  border-radius: 0;
  outline: none;
  margin-top: 7px;
}
.quantity__box .quantity__button:hover {
  cursor: pointer;
}
.qty_text {
  margin-left: 10px;
  margin-right: 10px !important;
  margin-top: 0px !important;
  font-size: 24px;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.quantity__box input {
  border-radius: 0;
  width: 65px;
  text-align: center;
  outline: none;
}

.min__qty__box__wrapped {
  overflow: hidden;
}

.min__qty__box {
  transform: translateY(-110px);
  transition: 0.35s all;
}

.min__qty__box_hide {
  /* transform: translateY(-110%); */
}

.min__qty__box_show {
  transform: translateY(0) !important;
}
