/**=====================
   19. Mega option CSS Start
==========================**/
.mega-inline {
	margin-bottom: -30px;
	display: flex;
	.card {
		border: 1px solid #f3f3f3;
		box-shadow: $card-box-shadow;
	}
	.mega-title-badge {
		display: table;
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
	}
	.media {
		margin-left: 20px;
		.radio {
			margin-left: 3px;
		}
	}
}
.mega-vertical{
	display: flex;
	flex-wrap: wrap;
	.card{
		box-shadow: $card-box-shadow;
	}
	.mega-title-badge{
		display:table;
		width: 100%;
		font-size: 14px;
    	font-weight: 600;
    	text-transform: uppercase;
	}
	.media {
		margin-left: 16px;
		.radio {
			margin-left: 3px;
		}
	}
}
.mega-title{
	font-weight: 600;
}
.mega-horizontal{
	display: flex;
	flex-wrap: wrap;
	.card{
		border: 1px solid #f3f3f3;
		box-shadow: $card-box-shadow;
	}
	.mega-title-badge{
		display:table;
		width: 100%;
		font-size: 14px;
    	font-weight: 600;
    	text-transform: uppercase;
	}
	.media {
		margin-left: 16px;
		.radio {
			margin-left: 3px;
		}
	}
}
.plain-style{
	.card{
		box-shadow: none;
		border: none !important;
	}
}
.border-style{
	.card{
		box-shadow: none;
		border: 1px solid $light-gray !important;
	}
}
.offer-style{
	.card{
		box-shadow: none;
		border: 1px dashed $light-gray !important;
	}
}
.rating-star-wrapper{
	color: #797979;
}
/**=====================
     19. Mega option CSS Ends
==========================**/