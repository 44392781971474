.gallery_images_cont .slick-prev::before{
 color: rgb(248, 172, 185)
}
.gallery_images_cont .slick-next::before{
    color: rgb(248, 172, 185)
   }
.gallery_images{
    width: 100%;
    height: 260px;
    
}